import React, { FC } from 'react';
import * as styles from './Login.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Link } from "@reach/router";
import LoginMaster from '../../../components/LoginMaster/LoginMaster';

const Login: React.FC = () => {
  return (<>
    <LoginMaster backPath='/'>
      <Base />
    </LoginMaster>
  </>);
};

const Base: FC = () => {
  return <>
    <div className={styles.Tiles}>
      <a href='/Login/Corporate'>
        <i>
          <FontAwesomeIcon icon={faBuilding} />
        </i>
        Corporate Login
      </a>

      <a href='/Login/Employee'>
        <i>
          <FontAwesomeIcon icon={faAddressCard} />
        </i>
        Employee Login
      </a>
    </div>
    <div className={styles.Link}>
      <p>Don't have a PrimeHR Account? Click <Link to="/Register">Here</Link> to create one</p>
    </div>
  </>
}

export default Login;
