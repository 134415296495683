import React from 'react';
import Layout from '../components/Layout/layout';
import Login from '../Views/Portal/Login/Login';

const LoginPage: React.FC = () => {
  return (
    <Layout NoFooter={true} NoHeader={true}>
      <Login />
    </Layout>
  );
};

export default LoginPage;
